const Logo = () => {
  return <svg width="167" height="29" viewBox="0 0 167 29" fill="none" xmlns="http://www.w3.org/2000/svg" data-sentry-element="svg" data-sentry-component="Logo" data-sentry-source-file="logo.tsx">
      <path d="M14.4945 6.57561L14.5989 28.6587L8.75241 28.6887L8.64809 6.60561L0.765896 6.64727L0.742188 1.41978L22.3894 1.30646L22.4147 6.53395L14.4961 6.57561H14.4945Z" fill="black" data-sentry-element="path" data-sentry-source-file="logo.tsx" />
      <path d="M44.8235 17.7338L33.0927 17.7938L33.1433 28.5621L27.2968 28.5938L27.1672 1.28316L33.0152 1.2515L33.069 12.6447L44.7982 12.583L44.8235 17.7338Z" fill="black" data-sentry-element="path" data-sentry-source-file="logo.tsx" />
      <path d="M124.528 28.0837L124.399 0.773132L130.246 0.743136L130.374 28.0537L124.528 28.0837Z" fill="black" data-sentry-element="path" data-sentry-source-file="logo.tsx" />
      <path d="M55.4752 23.3351L55.3946 6.24623L69.2718 6.17457L69.2481 1.06373L49.5245 1.16538L49.6525 28.476L69.7839 28.371L69.7602 23.2602L55.4752 23.3351Z" fill="black" data-sentry-element="path" data-sentry-source-file="logo.tsx" />
      <path d="M69.7089 12.4585L64.8235 12.4837L64.8473 17.6344L69.7328 17.6093L69.7089 12.4585Z" fill="black" data-sentry-element="path" data-sentry-source-file="logo.tsx" />
      <path d="M92.5687 13.294C92.6556 12.5425 92.8311 11.8309 93.0966 11.1594C93.4981 10.1429 94.0497 9.25634 94.7499 8.49813C95.45 7.73992 96.2798 7.14335 97.2392 6.71009C98.1986 6.27516 99.2339 6.05519 100.345 6.05019C101.554 6.04353 102.757 6.35681 103.958 6.98671C105.158 7.61827 106.204 8.47647 107.097 9.56463L110.481 5.177C109.836 4.47878 109.117 3.85221 108.325 3.29564C107.533 2.74073 106.692 2.2708 105.803 1.8842C104.913 1.49926 103.998 1.20431 103.06 1.00101C102.122 0.797707 101.184 0.69939 100.247 0.704389C98.2729 0.714387 96.449 1.076 94.7752 1.78588C93.0998 2.49743 91.6504 3.47394 90.4223 4.71541C89.1942 5.95688 88.2333 7.41831 87.5378 9.0997C86.9925 10.4162 86.6685 11.8259 86.5563 13.324L92.5703 13.2924L92.5687 13.294Z" fill="black" data-sentry-element="path" data-sentry-source-file="logo.tsx" />
      <path d="M107.145 19.6303C106.21 20.6235 105.155 21.41 103.972 21.9883C102.792 22.5665 101.608 22.8598 100.424 22.8648C99.3145 22.8698 98.2761 22.6615 97.312 22.2366C96.3478 21.8133 95.5133 21.2251 94.8052 20.4752C94.0987 19.7253 93.5392 18.8371 93.1283 17.8123C92.9607 17.3957 92.8295 16.9658 92.7284 16.5208L86.6385 16.5525C86.7997 17.7973 87.1064 18.9821 87.5695 20.1036C88.2681 21.8033 89.2243 23.2747 90.4398 24.5162C91.6536 25.7593 93.0966 26.7325 94.7641 27.4391C96.4332 28.1456 98.2287 28.4939 100.154 28.4839C102.054 28.4739 103.927 28.0223 105.773 27.1275C107.619 26.2343 109.229 25.0411 110.603 23.5514L107.143 19.6287L107.145 19.6303Z" fill="black" data-sentry-element="path" data-sentry-source-file="logo.tsx" />
      <path d="M160.921 0.580776L160.995 16.4599C161.005 18.4362 160.488 19.9877 159.445 21.1108C158.71 21.9023 157.795 22.4123 156.707 22.6506L156.733 28.1197C157.942 28.003 159.067 27.7481 160.102 27.3498C161.542 26.7966 162.761 26.01 163.755 24.9902C164.749 23.9703 165.514 22.7372 166.05 21.2908C166.586 19.8443 166.85 18.2229 166.842 16.4299L166.768 0.550781L160.921 0.580776Z" fill="black" data-sentry-element="path" data-sentry-source-file="logo.tsx" />
      <path d="M153.649 22.6356C152.611 22.3956 151.716 21.9057 150.972 21.1558C149.869 20.0443 149.312 18.4996 149.303 16.5215L149.228 0.642433L143.382 0.672428L143.456 16.5515C143.464 18.3462 143.75 19.9643 144.311 21.4041C144.874 22.8439 145.667 24.0687 146.696 25.0785C147.725 26.0883 148.961 26.8615 150.408 27.3998C151.41 27.7731 152.5 28.0114 153.673 28.1213L153.648 22.6339L153.649 22.6356Z" fill="black" data-sentry-element="path" data-sentry-source-file="logo.tsx" />
      <path d="M119.993 22.9418L115.107 22.967L115.131 28.1178L120.017 28.0926L119.993 22.9418Z" fill="black" data-sentry-element="path" data-sentry-source-file="logo.tsx" />
      <path d="M139.358 22.8434L134.473 22.8686L134.497 28.0193L139.382 27.9942L139.358 22.8434Z" fill="black" data-sentry-element="path" data-sentry-source-file="logo.tsx" />
    </svg>;
};
export default Logo;