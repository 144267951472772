import * as cheerio from "cheerio"
import { LocalStorage, SessionStorage, LOCAL_KEYS } from "./storages"

const loadJS = ({ id, src, callback }) => {
  // Use callback if the script element is existed
  if (document.getElementById(id)) {
    callback()
    return
  }

  // Create a async script element
  const script = document.createElement("script")
  script.id = id
  script.src = src
  script.async = true

  // Append to body
  const body = document.querySelector("body") || null
  if (body) {
    body.appendChild(script)
  }
}

export const getAccessToken = () => {
  const user =
    LocalStorage.get(LOCAL_KEYS.PROFILE, SessionStorage.get(LOCAL_KEYS.PROFILE, {}))

  return user?.access_token || null
}

const truncate = str => {
  if (str.length > 50) {
    return `${str.slice(0, 50)}...`
  }
  return str
}

export const fullName = (user, unknownName = "Anonymous", extend = "") => {
  const { first_name: firstName = "", last_name: lastName = "" } = user || {}
  if (!firstName && !lastName) {
    return unknownName + extend
  }
  if (!firstName) {
    return truncate(lastName) + extend
  }
  if (!lastName) {
    return truncate(firstName) + extend
  }
  return truncate(`${firstName} ${lastName}`) + extend
}

const hexToRGB = (hex, alpha) => {
  if (!hex) return null
  const r = Number.parseInt(hex.slice(1, 3), 16)
  const g = Number.parseInt(hex.slice(3, 5), 16)
  const b = Number.parseInt(hex.slice(5, 7), 16)

  if (alpha) {
    return `rgba(${r}, ${g}, ${b}, ${alpha})`
  }
  return `rgb(${r}, ${g}, ${b})`
}

export const formatHtmlBreak = text => {
  if (!text) return ""
  return text
    .replace(/\\n/g, "<br />")
    .replace(/<p data-f-id="pbf".*?<\/p>/g, "")
    .replace(
      /<p style="text-align:center;">Powered by <a href="https:\/\/www.froala.com\/wysiwyg-editor\?pb=1">Froala Editor<\/a><\/p>/g,
      ""
    )
}

export const removeNullParams = params => {
  return Object.keys(params).reduce((object, key) => {
    if (
      params?.[key] !== null &&
      params?.[key] !== undefined &&
      params?.[key] !== ""
    ) {
      object[key] = params[key]
    }
    return object
  }, {})
}

const formatCurrency = (value, currency = "VND") => {
  if (!value) return "0"
  return new Intl.NumberFormat("vi-VN", {
    style: "currency",
    currency
  }).format(value)
}

// 10000 -> 1,000
export const formatNumberCurrency = value => {
  if (!value) return "0"
  return value?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
}

// format 1000 -> 1,000đ
export const formatPrice = price => {
  if (!price) return "0đ"
  return `${formatNumberCurrency(price)}đ`
}


const extractFromHTML = (
  html: string,
  options?: { metaTags: string[] }
) => {

  if (!html) return {}

  const $ = cheerio.load(html)
  const output = {}
  try {
    const meta = $("meta").toArray() || undefined
    output.lang = $("html").attr("lang") || ""
    output.title = $("title").text() || ""
    output.favicon = $('link[rel="icon"]').attr("href") || ""
    meta.forEach((tag: any) => {
      const name = $(tag).attr("name")
      const property = $(tag).attr("property")
      if (name) output[name] = $(tag).attr("content") || ""
      if (property) output[property] = $(tag).attr("content") || ""
    })

    if (options?.metaTags) {
      const filteredOutput = options.metaTags.reduce(
        (acc: any, tag: string) => {
          if (output[tag]) {
            acc[tag] = output[tag]
          }
          return acc
        },
        {}
      )
      return filteredOutput
    }
    return output
  } catch (error) {
    console.error(error)
    return {}
  }
}

function removeEmptyElements(obj) {
  if (Array.isArray(obj)) {
    obj.forEach((element, index) => obj.splice(index, 1, removeEmptyElements(element)));
    return obj;
  }
  return Object.fromEntries(Object.entries(obj)
    .filter(([, v]) => (Array.isArray(v) ? v.length !== 0 : (v !== null && v !== '' && v !== undefined)))
    .map(([k, v]) => [k, v === (Object(v)) ? removeEmptyElements(v) : v]));
}
const getMetaTags = (html: string, options?: {
  [key: string]: any
}) => {
  const configData = extractFromHTML(html) || {}

  const metadata = {
    metadataBase: configData?.["URL"] ? new URL(configData?.["URL"]) : null,
    title: configData?.["title"],
    description: configData?.["description"],
    // themeColor: configData?.["theme-color"],
    keywords: configData?.["keywords"],
    authors: configData?.["author"],
    locale: configData?.["locale"] || configData?.["lang"] || "vi",
    applicationName: configData?.["application-name"],
    image: configData?.["image"],
    type: configData?.["type"],
    msapplication: {
      tooltip: configData?.["msapplication-tooltip"],
      startUrl: configData?.["msapplication-starturl"],
      name: configData?.["msapplication-name"],
      content: configData?.["msapplication-content"],
      tileColor: configData?.["msapplication-TileColor"],
      tileImage: configData?.["msapplication-tileimage"],
      config: configData?.["msapplication-config"],
    },
    icons: {
      icon: configData?.["icon"] || configData?.["favicon"] || "/assets/images/logos/apple-touch-icon.png",
      favicon: configData?.["favicon"] || "/assets/images/logos/apple-touch-icon.png",
      apple: configData?.["apple-touch-icon"] || "/assets/images/logos/apple-touch-icon.png",
    },
    alternates: {
      ...(configData?.["canonical"] || {}),
      ...(options?.canonical && {
        canonical: options?.canonical
      })
    },
    openGraph: {
      title: configData?.["og:title"],
      description: configData?.["og:description"],
      url: configData?.["og:url"],
      siteName: configData?.["og:site_name"],
      images: [
        {
          url: configData?.["og:image"],
          secureUrl: configData?.["og:image:secure_url"],
          width: configData?.["og:image:width"],
          height: configData?.["og:image:height"],
          alt: configData?.["og:image:alt"],
          type: configData?.["og:image:type"],
        }
      ],
      locale: configData?.["og:locale"] || configData?.["o:locale"],
      type: ['website', 'article', 'book', 'profile', 'video', 'music'].includes(configData?.["og:type"]) ? configData?.["og:type"] : 'website',
      price: configData?.["o:price:currency"],
    },
    twitter: {
      card: configData?.["twitter:card"],
      title: configData?.["twitter:title"],
      description: configData?.["twitter:description"],
      image: configData?.["twitter:image"],
    },
    robots: {
      index: true,
      follow: true,
      noarchive: true,
      nosnippet: true, // Prevents search engines from showing snippets of the page in search results.
      noimageindex: true,
      nocache: true, //Prevents search engines from caching the page.
    },
    appleWebApp: {
      title: configData?.["apple-mobile-web-app-title"],
      statusBarStyle: configData?.["apple-mobile-web-app-status-bar-style"],
      statusBarColor: configData?.["apple-mobile-web-app-status-bar-color"],
      icon: configData?.["apple-touch-icon"],
      iconSize: configData?.["apple-touch-icon"],
    }
  }
  return removeEmptyElements(metadata)
}

export const replaceDomain = (url, newDomain) => {
  // Create a URL object from the input URL
  const parsedUrl = new URL(url);
  
  // Replace the hostname with the new domain
  parsedUrl.hostname = newDomain;

  // Return the updated URL as a string
  return parsedUrl.toString();
}