import { makeStyles } from "tss-react/mui"

export const useStyles = makeStyles()(theme => ({
  root: {
    width: "auto",
    maxWidth: 400,
    [theme.breakpoints.down("md")]: {
      width: "100%"
    }
  },
  inputRoot: {
    height: 32,
    display: "flex",
    justifyContent: "center",
    "& button": {
      boxShadow: "none",
      outline: 0,
      background: "white",
      fontSize: 12,
      fontWeight: 400,
      color: "#262626",
      border: "1.5px solid #D1D1D1",
      borderRadius: "8px",
      minWidth: 40,
      padding: "16px",
      "&:hover": {
        background: "white",
        boxShadow: "none",
        outline: 0
      },
      "&.date": {
        width: "100%",
        textTransform: "capitalize",
        minWidth: 120
      }
    }
  },
  popover: {
    marginTop: 16,
    "& .MuiPopover-paper": {
      borderRadius: 10
    }
  }
}))
