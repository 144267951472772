import baseAPI from "./https"
import queryString from "query-string"

const API = name => ({
  getList: params => {
    const removeNullParams = Object.keys(params).reduce((object, key) => {
      if (params?.[key]) {
        object[key] = params[key]
      }
      return object
    }, {})
    const path = queryString.stringifyUrl({
      url: name,
      query: removeNullParams
    })
    return baseAPI.get(path)
  },
  getById: id => {
    return baseAPI.get(`${name}?id=${id}`)
  },
  create: params => {
    return baseAPI.post(`/${name}`, params)
  },
  update: params => {
    return baseAPI.put(`/${name}/${params.id}`, params)
  },
  deleteById: id => {
    return baseAPI.delete(`/${name}/${id}`)
  }
})

export default API
