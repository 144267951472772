"use client";

import { useMemo } from "react";
import useMutation from "@src/hooks/use-mutation";
import { Toastify, Icons } from "@src/components";
import { useCurrentUser } from "@src/hooks/use-current-user";
interface Props {
  productId: number;
  slug: string;
  onCallBackToggleLike?: () => void;
}
const FavoriteIcon = ({
  productId,
  slug,
  onCallBackToggleLike
}: Props) => {
  const {
    mutation
  } = useMutation();
  const {
    update,
    user
  } = useCurrentUser();
  const {
    product_ids
  } = user || {};
  const isLiked = useMemo(() => {
    return product_ids?.includes(productId);
  }, [product_ids?.length, productId]);
  const toggleLike = (status: boolean) => async () => {
    if (slug) {
      if (status) {
        await mutation({
          url: `/auth/wishlist/product/${slug}/addToWishlist`,
          method: "POST",
          onSuccess: res => {
            if (res) {
              Toastify.success(res.message || "Đã thêm vào yêu thích");
              update({
                ...user,
                product_ids: [...product_ids, productId],
                type: "LOCAL_UPDATE"
              });
              onCallBackToggleLike?.();
            }
          }
        });
      } else {
        await mutation({
          url: `/auth/wishlist/product/${slug}/removeFromWishlist`,
          method: "DELETE",
          onSuccess: res => {
            if (res) {
              Toastify.success(res.message || "Đã xóa khỏi yêu thích");
              update({
                ...user,
                product_ids: product_ids.filter(item => item !== productId),
                type: "LOCAL_UPDATE"
              });
              onCallBackToggleLike?.();
            }
          }
        });
      }
    }
  };
  return <Icons name="heart" type="button" sx={{
    padding: "5px !important",
    "&:hover": {
      borderRadius: "50%"
    },
    ...(isLiked && {
      "& svg ": {
        fill: "#FC5E5E",
        color: "#FC5E5E"
      }
    })
  }} onClick={toggleLike(!isLiked)} data-sentry-element="Icons" data-sentry-component="FavoriteIcon" data-sentry-source-file="favorite-Icon.tsx" />;
};
export default FavoriteIcon;