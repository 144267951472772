"use client"
import { useState, useCallback } from "react"
import { getDataFromResponse } from "@src/utils/api"
import fetchClient from "@src/api/lib/fetch-client"
import { MutationRequest } from "@src/types/api"

const useMutation = () => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [data, setData] = useState(null)
  const [othersData, setOthersData] = useState({})
  const [loadingWithKey, setLoadingWithKey] = useState<{
    [key: string]: boolean
  }>({})

  const mutation = useCallback(
    async ({
      method = "POST",
      url,
      params,
      queries,
      loadingKey,
      config,
      onSuccess,
      onFailed
    }: MutationRequest) => {
      try {
        setLoading(true)
        if (loadingKey) {
          setLoadingWithKey({ ...loadingWithKey, [loadingKey]: true })
        }
        const res = await fetchClient({
          method,
          url,
          body: JSON.stringify(params),
          queries,
          config
        })
        const { data: resData, ...rest } = getDataFromResponse(res)
        setData(resData)
        setOthersData(rest)

        setLoading(false)
        if (loadingKey) {
          setLoadingWithKey({ ...loadingWithKey, [loadingKey]: false })
        }
        onSuccess && onSuccess(resData)
        return res
      } catch (error) {
        setLoading(false)
        if (loadingKey) {
          setLoadingWithKey({ ...loadingWithKey, [loadingKey]: false })
        }
        setError(error)
        onFailed && onFailed(error)
      }
    },
    []
  )

  return {
    loading,
    loadingWithKey,
    error,
    data,
    othersData,
    mutation
  }
}

export default useMutation
