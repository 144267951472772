import { getSession } from "next-auth/react"
import Toastify from "@src/components/Toastify"
import queryString from "query-string"
import { removeNullParams } from "@src/utils/common"
import { LocalStorage, LOCAL_KEYS } from "@src/utils/storages"
import {
  ApiSession,
  DeviceSession,
  FetchClientType,
  ErrorResponseType
} from "@src/types/api"
import eventEmitter from "@src/services/event-emitter"
import { ApiErrorCode } from "@src/models/enum/api_error_code"
import { EventEmitterChannel } from "@src/models/enum/common"
import fetch from 'node-fetch'

var NUMBER_OF_RETRY = 3

const getErrorMessage = (error: ErrorResponseType) => {
  let msgError = ""
  if (error?.errors) {
    const { errors } = error
    Object.keys(errors).forEach(key => {
      if (Array.isArray(errors[key])) {
        msgError += errors[key].join(", ") + "\n"
      } else {
        msgError += errors[key] + "\n"
      }
    })
    return msgError
  }
  return error.message || error.error || error.statusText || "Đã có lỗi xảy ra!"
}

const handleRefreshTokenWithDevice = async (
  deviceSession: DeviceSession
): Promise<string | ErrorResponseType> => {
  try {
    const res = await fetchClient({
      method: "POST",
      url: "/login-with-device",
      body: JSON.stringify({
        device_token: deviceSession?.device_token
      })
    })
    const data = res?.data || {}
    LocalStorage.set(LOCAL_KEYS.DEVICE_SESSION, {
      access_token: data?.access_token,
      auth_type: data?.user?.auth_type || "",
      device_token: data?.user?.device_token || ""
    })
    return data?.access_token
  } catch (error) {
    throw error
  }
}

const handleError = async ({
  retryOptions,
  error,
  isLogin,
  deviceSession,
  config
}) => {
  try {
    const { deviceAuthenticated } = config || {}
    const errorJson = await error.json()
    switch (errorJson.code) {
      case ApiErrorCode.INVENTORY_OUT_OF_STOCK:
        eventEmitter.emit(EventEmitterChannel.CHECKOUT_ERROR, {
          type: ApiErrorCode.INVENTORY_OUT_OF_STOCK,
          inventory_id: errorJson.inventory_id,
          inventory_ids: errorJson.inventory_ids
        })
        break
      case ApiErrorCode.VOUCHER_INVAID:
        eventEmitter.emit(EventEmitterChannel.CHECKOUT_ERROR, {
          type: ApiErrorCode.VOUCHER_INVAID,
          vouchers: errorJson.vouchers
        })
        break;
      default:
        break
    }
    const msgError = getErrorMessage(errorJson)
    if (error.status === 401) {
      // refresh token and retry
      if (!isLogin && NUMBER_OF_RETRY > 1 && deviceAuthenticated) {
        NUMBER_OF_RETRY -= 1
        const deviceAccessToken =
          await handleRefreshTokenWithDevice(deviceSession)
        return fetchClient({
          ...retryOptions,
          token: deviceAccessToken
        })
      }
      NUMBER_OF_RETRY = 3
      Toastify.error(
        msgError || "Bạn cần đăng nhập để thực hiện chức năng này!"
      )

      if (!isLogin) {
        window.location.href = "/sign-in"
      }
      return
    }
    throw new Error(msgError || "Failed to fetch data")
  } catch (error) {
    Toastify.error(getErrorMessage(error))
    throw error
  }
}

async function fetchClient({
  method = "GET",
  url,
  body = "",
  queries = null,
  token,
  noContentType,
  config,
  onUploadProgress
}: FetchClientType) {
  const session = (await getSession()) as ApiSession
  const deviceSession = LocalStorage.get(LOCAL_KEYS.DEVICE_SESSION) || {}
  const accessToken =
    token || session?.accessToken || deviceSession?.access_token
  const baseUrl = process.env.NEXT_PUBLIC_API_URL
  let newUrl = url
  if (queries) {
    newUrl = queryString.stringifyUrl({
      url: newUrl,
      query: removeNullParams(queries)
    })
  }

  try {
    const response = await fetch(`${baseUrl}${newUrl}`, {
      method,
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + accessToken,
        ...(!noContentType && { "Content-Type": "application/json" })
      },
      body: body || undefined,
      ...(onUploadProgress && { onUploadProgress })
    })
    if (!response.ok) {
      throw response
    }

    const data = await response.json()

    return data
  } catch (error) {
    return handleError({
      retryOptions: { method, url, body, queries, token },
      error,
      isLogin: !!session?.accessToken,
      config,
      deviceSession
    })
  }
}

export default fetchClient
