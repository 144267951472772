"use client"

import Joi from "joi"
import { useCallback, useMemo, useState } from "react"
import { createJoiResolver } from "@src/utils/form"

/**
 * Transform Joi schema keys to resolver that used in React Hook Form
 * @param {*} schemaKeys An object with each key is a Joi schema type
 */
const useValidationResolver = (initKeys = {}, initMessages = {}) => {
  const [schemaKeys, setSchemaKeys] = useState(initKeys)
  const [schemaMessages, setSchemaMessages] = useState(initMessages)

  const validationSchema = useMemo(() => {
    return Joi.object(schemaKeys)
      .unknown(true)
      .messages({
        "string.empty": "auth.validations.required",
        "any.required": "auth.validations.required",
        ...schemaMessages
      })
  }, [schemaKeys, schemaMessages])

  const validationResolver = useCallback(createJoiResolver, [])

  const appendSchema = keys => {
    setSchemaKeys(prevSchema => ({
      ...prevSchema,
      ...keys
    }))
  }

  const appendMessages = messages => {
    setSchemaMessages(prevMessages => ({
      ...prevMessages,
      ...messages
    }))
  }
  // useEffect(() => {
  // 	setSchemaKeys(prevSchema => ({
  // 		...prevSchema,
  // 		...initKeys
  // 	}))
  // }, [initKeys])

  return {
    appendSchema,
    appendMessages,
    validationSchema,
    validationResolver
  }
}

export default useValidationResolver
