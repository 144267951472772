const set = (key: string, value: any) => {
  try {
    window.localStorage.setItem(key, JSON.stringify(value))
  } catch (error) {
    console.error(error)
  }
}

const get = (key: string, defaultValue?: any) => {
  try {
    const value = window.localStorage.getItem(key)
    try {
      return JSON.parse(value)
    } catch (e) {
      return value || defaultValue
    }
  } catch (error) {
    // console.error(err)
  }
}

const clear = (key: string) => {
  try {
    window.localStorage.removeItem(key)
  } catch (error) {
    console.error(error)
  }
}

const clearAll = () => {
  try {
    window.localStorage.clear()
  } catch (error) {
    console.error(error)
  }
}

const localStorageService = {
  set,
  get,
  clear,
  clearAll
}

export default localStorageService
