import { makeStyles } from "tss-react/mui"

export const useStyles = makeStyles()(theme => ({
  singleDatePickerRoot: {
    display: "flex",
    justifyContent: "space-between",
    background: "white",
    "& .datepicker": {
      "& .DayPicker-Day": {
        padding: "0.5em ",
        border: "20px solid transparent",
        borderRadius: 0,
        [theme.breakpoints.down("md")]: {
          padding: 0
        }
      }
    },
    "& .group-button": {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-around",
      borderLeft: "1px solid #dbdbdb",
      padding: theme.spacing(1),
      width: 124,
      "& button": {
        fontSize: 14,
        lineHeight: "20px",
        textTransform: "initial",
        boxShadow: "none",
        outline: 0,
        border: "none"
      },
      [theme.breakpoints.down("md")]: {
        display: "none"
      }
    }
  }
}))
