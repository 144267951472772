import { useMemo } from "react";
import TableBody from "@mui/material/TableBody";
import Checkbox from "@mui/material/Checkbox";
import { MuiTableCell, MuiTableRow } from "../styles";
import Skeleton from "@mui/material/Skeleton";
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}
function getComparator(order, orderBy) {
  return order === "desc" ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}
export default function EnhancedTableBody(props) {
  const {
    showCheckbox,
    headCells,
    rows = [],
    loading,
    selected,
    page,
    order,
    orderBy,
    rowsPerPage,
    onClick,
    isSelectAll,
    isExceptIds
  } = props;
  const isSelected = name => {
    if (isExceptIds && isSelectAll) return selected.indexOf(name) === -1;
    if (!isSelectAll) return selected.indexOf(name) !== -1;
    return selected.indexOf(name) !== -1 || isSelectAll;
  };

  // const visibleRows = useMemo(
  // 	() =>
  // 		stableSort(rows, getComparator(order, orderBy)).slice(
  // 			page * rowsPerPage,
  // 			page * rowsPerPage + rowsPerPage
  // 		),
  // 	[order, orderBy, page, rowsPerPage, rows?.length]
  // )

  const visibleRows = order && orderBy ? stableSort(rows, getComparator(order, orderBy)) : rows;
  const renderTableBody = () => {
    if (loading) {
      return new Array(6).fill(0).map((_, idx) => <MuiTableRow hover role="checkbox" tabIndex={-1} sx={{
        cursor: "pointer"
      }} key={idx}>
          {new Array(headCells.length).fill("0").map((column, index) => <MuiTableCell align="left" key={index}>
              <Skeleton animation="wave" />
            </MuiTableCell>)}
        </MuiTableRow>);
    }
    if (loading !== undefined && rows.length === 0) {
      return <MuiTableRow style={{
        height: 80
      }}>
          <MuiTableCell colSpan={headCells.length + 1} sx={{
          fontSize: 16,
          textAlign: "center"
        }}>
            No results found
          </MuiTableCell>
        </MuiTableRow>;
    }
    return visibleRows.map((row, index) => {
      const isItemSelected = isSelected(row.id);
      const labelId = `enhanced-table-checkbox-${index}`;
      return <MuiTableRow hover onClick={event => onClick(event, row.id)} role="checkbox" aria-checked={isItemSelected} tabIndex={-1} key={row.id} selected={isItemSelected} sx={{
        cursor: "pointer"
      }}>
          {showCheckbox && <MuiTableCell padding="checkbox">
              <Checkbox color="primary" checked={isItemSelected} inputProps={{
            "aria-labelledby": labelId
          }} />
            </MuiTableCell>}

          {headCells.map((header, indexHeadCell) => <MuiTableCell key={indexHeadCell} align={header?.align || "left"}>
              {row?.[header?.id]}
            </MuiTableCell>)}
        </MuiTableRow>;
    });
  };
  return <TableBody data-sentry-element="TableBody" data-sentry-component="EnhancedTableBody" data-sentry-source-file="index.tsx">{renderTableBody()}</TableBody>;
}