"use client"

import { useEffect } from "react"

const getMobileDetect = (userAgent: any) => {
  const isAndroid = () => Boolean(userAgent.match(/Android/i))
  const isIos = () => Boolean(userAgent.match(/iPhone|iPad|iPod/i))
  const isOpera = () => Boolean(userAgent.match(/Opera Mini/i))
  const isWindows = () => Boolean(userAgent.match(/IEMobile/i))
  const isSSR = () => Boolean(userAgent.match(/SSR/i))
  const isMobile = () =>
    Boolean(isAndroid() || isIos() || isOpera() || isWindows())
  const isDesktop = () => Boolean(!isMobile() && !isSSR())

  return {
    isAndroid: isAndroid(),
    isIos: isIos(),
    isOpera: isOpera(),
    isWindows: isWindows(),
    isSSR: isSSR(),
    isMobile: isMobile(),
    isDesktop: isDesktop()
  }
}
const useMobileDetect = () => {
  const userAgent =
    typeof navigator === "undefined" ? "SSR" : navigator.userAgent
  return {
    ...getMobileDetect(userAgent),
  }
}

export default useMobileDetect
