import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import { Icons } from "@src/components";
const DialogTitle = props => {
  const {
    children,
    classes,
    onClose
  } = props;
  return <Stack sx={{
    py: 1.5,
    px: 2
  }} flex justifyContent="space-between" alignItems="center" flexDirection="row" data-sentry-element="Stack" data-sentry-component="DialogTitle" data-sentry-source-file="ModalHeader.tsx">
      <Typography variant="h4" data-sentry-element="Typography" data-sentry-source-file="ModalHeader.tsx">{children}</Typography>
      <Icons sx={{
      backgroundColor: "transparent !important"
    }} name="close" type="button" onClick={onClose} data-sentry-element="Icons" data-sentry-source-file="ModalHeader.tsx" />
    </Stack>;
};
export default DialogTitle;