"use client";
import * as React from 'react'

const useDomLoaded = () => {
  const [isDomLoaded, setIsDomLoaded] = React.useState(false)

  React.useEffect(() => {
    setIsDomLoaded(true)
  }, [])
  
  return isDomLoaded
}

export default useDomLoaded