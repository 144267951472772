import React from "react";
import Typography from "@mui/material/Typography";
const PriceTypography = ({
  children,
  isNull,
  ...rest
}) => {
  if (isNull) return null;
  return <Typography {...rest} sx={{
    opacity: 0.5,
    textDecoration: "line-through"
  }} data-sentry-element="Typography" data-sentry-component="PriceTypography" data-sentry-source-file="index.tsx">
      {children}
    </Typography>;
};
export default PriceTypography;