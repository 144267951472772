export const getDataFromResponse = res => {
  let data = null
  let pagination = null
  if (res?.data?.data?.items) {
    const { items, ...rest } = res.data.data
    data = items
    pagination = rest
  } else if (res?.data?.data) {
    const { data: resData, ...rest } = res?.data || {}
    data = resData
    pagination = rest
  } else if (res?.data?.items) {
    const { items, ...rest } = res.data
    data = items
    pagination = rest
  } else if (res?.data) {
    const { data: resData, ...rest } = res
    data = resData
    pagination = rest
  } else if (res?.length > 0) {
    data = res
    pagination = null
  }
  return { data, pagination }
}
