"use client"

import { useCallback, useEffect, useState, useRef } from "react"
import debounce from "lodash.debounce"

function useDebouncedCallback(callback, delay) {
  return useCallback(debounce(callback, delay), [callback, delay])
}

function useDebounce(value, delay) {
  const previousValue = useRef(value)
  const [current, setCurrent] = useState(value)
  const debouncedCallback = useDebouncedCallback(
    value => setCurrent(value),
    delay
  )
  useEffect(() => {
    // doesn't trigger the debounce timer initially
    if (value !== previousValue.current) {
      debouncedCallback(value)
      previousValue.current = value
      // cancel the debounced callback on clean up
      return debouncedCallback.cancel
    }
  }, [value, debouncedCallback])
  return current
}

export default useDebounce
