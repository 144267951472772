"use client"

import ApiCurd from "@src/api/base"
import { useState, useEffect, useCallback, useMemo } from "react"
import { getDataFromResponse } from "@src/utils/api"
import ProductModel from "@src/models/product"

const defaultStore = {
  entries: [],
  pagination: false
}

const useBase = props => {
  const { api, name, params } = props || {}
  const [detail, setDetail] = useState(null)
  const [store, setStore] = useState(defaultStore)
  const [loadingState, setLoading] = useState({
    fetching: undefined,
    fetchingId: undefined,
    creating: undefined,
    updating: undefined,
    deleting: undefined
  })

  const APICaller = useMemo(() => api || ApiCurd(name), [name, api])

  const setLoadingState = (attribute, status) => {
    const loadingData = JSON.parse(
      JSON.stringify({ ...(loadingState || {}), [attribute]: status })
    )
    setLoading(loadingData)
  }

  const handlerAPI = useCallback(async (api, loadingKey = "loading") => {
    setLoadingState(loadingKey, true)
    try {
      const res = await api
      setLoadingState(loadingKey, false)
      return res
    } catch (error) {
      setLoadingState(loadingKey, false)
    }
  }, [])

  const getList = useCallback(async payload => {
    setStore(defaultStore)
    setTimeout(async () => {
      const res = await handlerAPI(APICaller.getList(payload), "fetching")
      // is parse data from response
      const { data, pagination } = getDataFromResponse(res)

      const { current_page = 1 } = pagination || {}
      const newData =
        payload?.no_pagination && current_page > 1
          ? [...store.entries, ...data]
          : data
      setStore({ entries: newData, pagination })
    }, 0)
  }, [])

  const getById = useCallback(async id => {
    setDetail(null)
    const res = await handlerAPI(APICaller.getById(id), "fetchingId")
    const data = res?.data?.data || res?.data
    if (data) {
      setDetail(ProductModel.fromJson(data))
      return data
    }
  }, [])

  const create = useCallback(payload => {
    return handlerAPI(APICaller.create(payload), "creating")
  }, [])

  const update = useCallback(payload => {
    return handlerAPI(APICaller.update(payload), "updating")
  }, [])

  const deleteById = useCallback(id => {
    return handlerAPI(APICaller.deleteById(id), "deleting")
  }, [])

  useEffect(() => {
    if (params) {
      getList(params)
    }
  }, [])

  return {
    detail,
    ...store,
    ...loadingState,
    Service: {
      setStore,
      handlerAPI,
      getList,
      getById,
      create,
      update,
      deleteById
    }
  }
}

export default useBase
