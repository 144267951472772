"use client"

import { useSession } from "next-auth/react"

const parseToArray = str => {
  if (typeof str === "string") {
    return str.split(",").filter(Boolean) || []
  }

  if (Array.isArray(str)) {
    return str
  }
  return []
}

export const useCurrentUser = () => {
  const { data: session, status, update } = useSession()

  if (!session?.user) {
    return {}
  }

  return {
    user: {
      ...(session?.user || {}),
      product_ids: parseToArray(session?.user?.product_ids),
      collection_ids: parseToArray(session?.user?.collection_ids),
      event_ids: parseToArray(session?.user?.event_ids),
      blog_ids: parseToArray(session?.user?.blog_ids)
    },
    status,
    update
  }
}
