import FormMapContainerField from "./MapPicker/FormMapContainer"
import FormCheckBoxField from "./FormCheckBox"
import FormDateTimePickerField from "./FormDateTimePicker"
import FormGroupRadioField from "./FormGroupRadio"
import FormMapSelectField from "./FormMapSelectField"
import FormMuiDatePickerField from "./FormMuiDatePicker"
import FormPhoneNumberField from "./FormPhoneNumber"
import FormSelectField from "./FormSelect"
import FormSwitchField from "./FormSwitchField"
import FormTextField from "./FormTextField"
import FormAttchmentField from "./FormAttachment"
import FormLabel from "./FormLabel"

export const FIELD_MAP = {
  Text: FormTextField,
  Checkbox: FormCheckBoxField,
  DateTimePicker: FormDateTimePickerField,
  GroupRadio: FormGroupRadioField,
  MapSelect: FormMapSelectField,
  DatePicker: FormMuiDatePickerField,
  PhoneNumber: FormPhoneNumberField,
  Select: FormSelectField,
  Switch: FormSwitchField,
  Attachment: FormAttchmentField,
  FormLabel
}

export {
  FormMapContainerField,
  FormCheckBoxField,
  FormDateTimePickerField,
  FormGroupRadioField,
  FormMapSelectField,
  FormMuiDatePickerField,
  FormPhoneNumberField,
  FormSelectField,
  FormSwitchField,
  FormTextField,
  FormAttchmentField,
  FormLabel
}
