import axios from "axios"
import queryString from "query-string"
import { LocalStorage, SessionStorage, LOCAL_KEYS } from "@src/utils/storages"
import { getAccessToken } from "@src/utils/common"
import { Toastify } from "@src/components"

export const awsAPI = axios.create({
  paramsSerializer: params => queryString.stringify(params)
})

const baseAPI = axios.create({
  baseURL: process.env.NEXT_PUBLIC_API_URL,
  headers: {
    "content-type": "application/json"
  },
  paramsSerializer: params => queryString.stringify(params)
})

baseAPI.interceptors.request.use(async (config) => {
  const accessToken = getAccessToken()
  if (accessToken) {
    config.headers.Authorization = accessToken
  }

  // All request need pass language - we use token from local storage
  const languageCode = LocalStorage.get(LOCAL_KEYS.LANGUAGE_TOKEN, "en")
  config.headers["accept-language"] = languageCode
  config.headers["show-toast"] =
    config['showToast'] !== undefined ? config['showToast'] : true

  return config
})

baseAPI.interceptors.response.use(
  response => {
    if (response && response.data) {
      return response.data
    }

    return response
  },
  error => {
    const isToken = getAccessToken()
    if (parseInt(error?.response?.status) === 401 && isToken !== null) {
      LocalStorage.clearAll()
      SessionStorage.clearAll()
      window.location.replace("/auth/login")
    } else {
      const showToast = error?.config?.headers?.["show-toast"]
      const errorMessage = error?.response?.data || ""
      showToast &&
        Toastify.error(
          errorMessage.message || errorMessage.error || "Something went wrong!"
        )
      throw errorMessage
    }
  }
)

export default baseAPI

export const dynamic = 'force-dynamic';