const set = (key: string, value: any) => {
  try {
    window.sessionStorage.setItem(key, JSON.stringify(value))
  } catch (error) {
    console.error(err)
  }
}

const get = (key: string, defaultValue?: {
  [key: string]: any
}) => {
  try {
    const value = window.sessionStorage.getItem(key)
    try {
      return JSON.parse(value)
    } catch (e) {
      return value || defaultValue
    }
  } catch (error) {
    // console.error(err)
  }
}

const clear = (key: string) => {
  try {
    window.sessionStorage.removeItem(key)
  } catch (error) {
    console.error(err)
  }
}

const clearAll = () => {
  try {
    window.sessionStorage.clear()
  } catch (error) {
    console.error(err)
  }
}

const SessionStorageService = {
  set,
  get,
  clear,
  clearAll
}

export default SessionStorageService
