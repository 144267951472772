"use client";

import React from "react";
import { NextImage } from "@src/components";
import { Autoplay, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import useWindowDimensions from "@src/hooks/use-window-dimensions";
import Typography from "@mui/material/Typography";
import { makeStyles } from "tss-react/mui";
const useStyles = makeStyles()((theme, {
  width
}) => ({
  paginationBullet: {
    margin: "10px 10px",
    width: "8px",
    height: "8px",
    textAlign: "center",
    color: "#000",
    opacity: 1,
    background: "#fff",
    borderRadius: "50%",
    cursor: "pointer",
    [theme.breakpoints.up("sm")]: {
      width: "14px",
      height: "14px",
      margin: "40px 10px"
    }
  },
  paginationBulletActive: {
    outline: "4px solid #ff85c0",
    boxSizing: "border-box"
  },
  mySwiper: {
    width: "100%",
    "& .swiper-wrapper ": {
      height: "calc(100vh - 140px)"
    },
    [theme.breakpoints.down("sm")]: {
      "& .swiper-wrapper ": {
        height: width
      }
    }
  }
}));
function Carousel(props) {
  const {
    width
  } = useWindowDimensions();
  const {
    classes
  } = useStyles({
    width: width
  });
  const {
    SliderItemComponent,
    pagination
  } = props;
  return <Swiper pagination={{
    clickable: true,
    bulletClass: classes.paginationBullet,
    bulletActiveClass: classes.paginationBulletActive,
    ...pagination
  }} className={classes.mySwiper} modules={[Pagination, Autoplay]} autoplay={{
    delay: 5000
  }} data-sentry-element="Swiper" data-sentry-component="Carousel" data-sentry-source-file="index.tsx">
      {(props?.data || []).map(item => <SwiperSlide key={item.id}>
          {SliderItemComponent ? <SliderItemComponent itemData={item} /> : <>
              {item?.name && <Typography variant="h3" component="h3" sx={{
          fontSize: {
            xs: "14px",
            md: "48px"
          },
          fontWeight: 600,
          color: "#fff",
          position: "absolute",
          bottom: {
            xs: "40px",
            md: "96px"
          },
          zIndex: 2,
          width: "100%",
          textAlign: "center"
        }}>
                  {item.name}
                </Typography>}
              <NextImage key={item.id} src={item.img} alt={item.id} loading="lazy" sx={{
          "& img": {
            objectFit: "cover"
          },
          "&:before": {
            content: '""',
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            background: "linear-gradient(180deg, rgba(0, 0, 0, 0.00) 57.81%, #000 100%)",
            zIndex: 1
          }
        }} />
            </>}
        </SwiperSlide>)}
    </Swiper>;
}
export default Carousel;