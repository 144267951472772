export const ROUTES = {
  HOME_PAGE: "/",
  BEST_SELLER: "/best-seller",
  NEW_IN: "/new-in",
  ON_SALE: "/on-sale",

  PRODUCT: "/product",
  PRODUCT_CATEGORY: "/product/category",

  COLLECTION: "/collection",

  // Introduce
  INTRODUCE: "/introduce",
  RECRUITMENT: "/introduce/recruitment",
  RECRUITMENT_JOB: "/introduce/recruitment/job",
  LIFE: "/introduce/recruitment/life",
  LIFE_HOT_JOB: "/introduce/recruitment/life/hot-job",
  BRANCH: "/introduce/branch",
  ABOUT_US: "/introduce/about-us",
  MEMBERSHIP: "/introduce/membership",
  FAQ: "/introduce/faq",

  EVENT: "/event",
  SEARCH: "/search",

  // Auth
  SIGN_IN: "/sign-in",
  SIGN_IN_WITH_PASSWORD: "/sign-in?tab=password",
  SIGN_UP: "/sign-up",
  FORGOT_PASSWORD: "/forgot-password",
  RESET_PASSWORD: "/reset-password",

  // Cart
  CART: "/cart",
  CART_PAYMENT: "/cart/payment",

  DEVICE: "/device",
  DEVICE_ORDERS: "/device/orders",
  DEVICE_ADDRESS: "/device/address",

  // Profile
  PROFILE: "/profile",
  PROFILE_ORDERS: "/profile/orders",
  PROFILE_NOTIFICATION: "/profile/notification",
  PROFILE_INFO: "/profile/info",
  PROFILE_FAVORITE_PRODUCTS: "/profile/favorite-products",
  PROFILE_ADDRESS: "/profile/address",
  PROFILE_ADDRESS_ADD: "/profile/address/add",
  PROFILE_ADDRESS_EDIT: "/profile/address/edit",
  PROFILE_VOUCHER: "/profile/wallet-voucher",

  BLOG: "/blog",
  BLOG_SEARCH: "/blog/search",
  BLOG_CATEGORY_BLOG: "/blog/category/blog",
  BLOG_CATEGORY_STYLE: "/blog/category/style",

  PRIVACY_POLICY: "/chinh-sach-bao-mat",
}


const IGNORE_META_PATH = [
  '/blog/',
  '/product/',
]
