import Https from "./base/https"
import queryString from "query-string"

const ProductApi = {
  getList: params => {
    return Https.get(`/products/paginate?${queryString.stringify(params)}`)
  },

  getById: slug => {
    return Https.get(`/products/${slug}`)
  },

  getRelated: (slug, params) => {
    return Https.get(
      `/products/${slug}/related?${queryString.stringify(params)}`
    )
  },

  searchList: params => {
    return Https.get(`/products/search?${queryString.stringify(params)}`)
  }
}

export default ProductApi
