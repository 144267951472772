export const FIELD_HEIGHT = 52

export const styleCommon = {
  controller: {
    outline: "none",
    border: "none",
    boxSize: "border-box",
    fontSize: 16,
    "& > div:nth-of-type(1)": {
      height: FIELD_HEIGHT,
      fontSize: 16,
      borderRadius: 16,
      overflow: "hidden",
      boxShadow: "none !important",
      "&.MuiInputBase-multiline": {
        height: "unset"
      }
    },
    "& fieldset": {
      outline: "none",
      boxShadow: "unset !important",
      borderColor: "#C2C7D0",
      top: "-9px"
    },
    "& input, select, textarea": {
      outline: "none",
      backgroundColor: "transparent",
      height: "100%",
      lineHeight: "28px",
      "&::placeholder": {
        fontWeight: 400,
        fontSize: 14,
        color: "#8A8A8A",
        lineHeight: "18px",
        opacity: 0.6
      },
      "&:focus ~ fieldset": {
        border: "1px solid #5E78F6"
      }
    }
  },
  label: {
    height: {
      xs: "auto",
      lg: "24px"
    },
    fontSize: 16,
    lineHeight: "24px",
    color: "#000000",
    fontWeight: "600",
    marginBottom: "16px",
    display: "inline-block",
    whiteSpace: {
      xs: "unset",
      lg: "nowrap"
    },
    "& .required": {
      color: "#F5222D",
      display: "inline",
      paddingLeft: "4px"
    }
  },
  errorText: {
    color: "#FF0000",
    marginLeft: 2,
    marginRight: 2,
    marginTop: "8px",
    fontSize: "14px"
  },
  errorField: {
    backgroundColor: "#FFF1F0 !important",
    borderRadius: "16px !important",
    "& input, select, textarea": {
      backgroundColor: "#FFF1F0 !important",
      "&:focus ~ fieldset": {
        boxShadow: "0px 0px 0px 0px !important",
        borderColor: "#f5222d !important"
      }
    },
    "& fieldset": {
      boxShadow: "0px 0px 0px 0px !important"
    }
  }
}
