/* eslint-disable react/display-name */
"use client";

import { Controller } from "react-hook-form";
import Select, { components } from "react-select";
import classNames from "classnames";
import { makeStyles } from "tss-react/mui";
import { Icons } from "@src/components";
import { styleCommon, FIELD_HEIGHT } from "./styleCommon";
import { useEffect, useRef } from "react";
import NextImage from "next/image";
const useStyles = makeStyles()({
  root: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    "& .react-select-container": {
      "&:focus-visible": {
        outline: "none"
      }
    },
    "& .react-select__placeholder": {
      fontWeight: 400,
      fontSize: 14,
      color: "#8A8A8A",
      lineHeight: "18px",
      opacity: 0.6
    },
    "& .form-control .react-select__control--is-disabled": {
      backgroundColor: "#E7E7E7"
    },
    "& .react-select__control--is-focused": {
      boxShadow: "0 0 0 1px #C2C7D0",
      "&:hover, &:focus-visible": {
        borderColor: "#C2C7D0",
        boxShadow: "0 0 0 1px #C2C7D0"
      }
    },
    "& .react-select__option": {
      display: "flex",
      alignItems: "center",
      color: "#141414",
      fontSize: 14,
      lineHeight: "24px",
      padding: "12px 16px",
      borderBottom: "1px solid white",
      backgroundColor: "#F6F6F6",
      "&.react-select__option--is-selected": {
        backgroundColor: "#E7E7E7"
      },
      "&:hover": {
        backgroundColor: "#E7E7E7"
      },
      "&:last-child": {
        borderBottom: "none"
      },
      "& svg, img": {
        marginRight: 12,
        width: 24,
        height: 24,
        borderRadius: "100%"
      }
    }
  },
  ...styleCommon
});
const DropdownIndicator = () => {
  return <Icons name="arrow-down" type="button" data-sentry-element="Icons" data-sentry-component="DropdownIndicator" data-sentry-source-file="FormSelect.tsx" />;
};
const customStyles = msgError => ({
  control: styles => ({
    ...styles,
    height: FIELD_HEIGHT,
    borderRadius: 8,
    borderColor: msgError ? "red" : "#C2C7D0",
    backgroundColor: msgError ? "#FFF1F0 !important" : "#FFFFFF",
    "&:hover": {
      borderColor: "red",
      color: "red"
    }
  }),
  valueContainer: styles => ({
    ...styles,
    height: "100%"
  }),
  indicatorSeparator: styles => ({
    ...styles,
    display: "none"
  }),
  menu: styles => ({
    ...styles,
    zIndex: 1000
  }),
  menuList: styles => ({
    ...styles,
    borderRadius: 4,
    padding: 0,
    margin: 0
  })
});
const Option = props => {
  const ref = useRef();
  useEffect(() => {
    props.isSelected && ref.current.scrollIntoView();
  }, [props.isSelected]);
  return <components.Option {...props} innerRef={ref} data-sentry-element="unknown" data-sentry-component="Option" data-sentry-source-file="FormSelect.tsx" />;
};
const FormSelect = props => {
  const {
    classes
  } = useStyles();
  const {
    required,
    showAvatar,
    name,
    label,
    control,
    errors,
    options,
    disabled,
    showLabel = true,
    components,
    placeholder,
    hiddenRequired,
    ...rest
  } = props;
  const msgError = errors?.[name]?.message;
  return <div className={classes.root} data-sentry-component="FormSelect" data-sentry-source-file="FormSelect.tsx">
      {showLabel && label !== undefined && <label className={classNames(classes.label, {
      [classes.errorLabel]: Boolean(msgError)
    })}>
          {label}
          {!hiddenRequired && required && <span className="required">*</span>}
        </label>}
      {control ? <Controller className={classes.controller} name={name} control={control} render={({
      field: {
        onChange,
        onBlur,
        value,
        name,
        ref
      },
      fieldState: {
        invalid,
        isTouched,
        isDirty,
        error
      },
      formState
    }) => <Select required={required} menuPosition="fixed" menuPlacement="auto" className={classNames("react-select-container", {
      [classes.errorField]: Boolean(msgError)
    })} classNamePrefix="react-select" components={{
      ...components,
      Option
    } || {
      DropdownIndicator,
      Option
    }} {...showAvatar && {
      formatOptionLabel: ({
        photo_url,
        label
      }) => <div className="option-elm">
                    {photo_url ? <NextImage src={photo_url} alt="avatar" /> : <Icons name="avatar" />}
                    <span>{label}</span>
                  </div>
    }} isSearchable={false} options={options} styles={customStyles(msgError)} isDisabled={disabled} onBlur={onBlur} // notify when input is touched
    onChange={onChange} // send value to hook form
    value={value} // select value to show
    name={name} style={{
      backgroundColor: "red"
    }} placeholder={placeholder} {...rest} />} /> : <Select styles={customStyles(errors)} isDisabled={disabled} />}
      {msgError && <span className={`error-text ${classes.errorText}`}>{msgError}</span>}
    </div>;
};
export default FormSelect;