import React from "react";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { Icons } from "@src/components";
import { JobStatus, CustomButton } from "..";
import Skeleton from "@mui/material/Skeleton";
import DangerouslySetInnerHTML from "@src/components/DangerouslySetInnerHTML";
const JobCard = props => {
  const {
    loading,
    featured,
    is_expired,
    title,
    description,
    jobType,
    department,
    onClickDetail
  } = props;
  if (loading) {
    return <Paper sx={{
      borderRadius: 1,
      padding: 1,
      boxShadow: "4px 0px 32px -2px rgba(16, 24, 40, 0.08)",
      display: "flex",
      cursor: "pointer",
      position: "relative",
      overflow: "hidden",
      pointerEvents: "none",
      "&:hover": {
        "& #job-hover": {
          display: "block",
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundColor: "#808080b0",
          borderRadius: 1
        },
        "& .btn-detail": {
          bottom: "50%",
          transform: "translateY(50%)"
        }
      }
    }}>
        <Box sx={{
        marginRight: 1
      }}>
          <Skeleton animation="wave" height={50} width={40} />
        </Box>
        <Stack width="100%" flexDirection="column" p={2}>
          <Skeleton animation="wave" height={24} width={200} />
          <Stack alignItems="center" spacing={1} mt={0.5} mb={1.5} sx={{
          "& p": {
            fontSize: "12px",
            color: "#7D7D7D"
          }
        }}>
            <Skeleton animation="wave" height={20} width={120} />
          </Stack>
          <Skeleton animation="wave" variant="rectangular" height={60} />
        </Stack>
      </Paper>;
  }
  return <Paper sx={{
    borderRadius: 1,
    padding: 1,
    boxShadow: "4px 0px 32px -2px rgba(16, 24, 40, 0.08)",
    height: "100%",
    display: "flex",
    cursor: "pointer",
    position: "relative",
    overflow: "hidden",
    "&:hover": {
      "& #job-hover": {
        display: "block",
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "#000",
        opacity: 0.5,
        borderRadius: 1
      },
      "& .btn-detail": {
        bottom: "50%",
        transform: "translateY(50%)"
      }
    }
  }} data-sentry-element="Paper" data-sentry-component="JobCard" data-sentry-source-file="index.tsx">
      <div id="job-hover" />
      <Box sx={{
      margin: "16px 0 0 16px"
    }} data-sentry-element="Box" data-sentry-source-file="index.tsx">
        <JobStatus featured={featured} is_expired={is_expired} data-sentry-element="JobStatus" data-sentry-source-file="index.tsx" />
      </Box>
      <Stack flexDirection="column" p={2} data-sentry-element="Stack" data-sentry-source-file="index.tsx">
        <Typography className="truncate-1" variant="h5" component="p" fontWeight={700} fontSize={16} lineHeight="24px" data-sentry-element="Typography" data-sentry-source-file="index.tsx">
          {title}
        </Typography>
        <Stack alignItems="center" spacing={1} mt={0.5} mb={1.5} sx={{
        "& p": {
          fontSize: "12px",
          color: "#7D7D7D"
        }
      }} data-sentry-element="Stack" data-sentry-source-file="index.tsx">
          <Typography variant="body" component="p" data-sentry-element="Typography" data-sentry-source-file="index.tsx">
            {jobType}
          </Typography>
          <Icons name="stroke" data-sentry-element="Icons" data-sentry-source-file="index.tsx" />
          <Typography variant="body" component="p" data-sentry-element="Typography" data-sentry-source-file="index.tsx">
            {department}
          </Typography>
        </Stack>
        <DangerouslySetInnerHTML className="truncate-3" html={description} data-sentry-element="DangerouslySetInnerHTML" data-sentry-source-file="index.tsx" />
      </Stack>
      <Box className="btn-detail" sx={{
      position: "absolute",
      bottom: -40,
      transaction: "all 0.3s ease-in-out",
      left: 0,
      right: 0,
      width: "100%"
    }} data-sentry-element="Box" data-sentry-source-file="index.tsx">
        <CustomButton sx={{
        margin: "auto",
        border: "none",
        fontSize: "14px",
        backgroundColor: "#FF85C0 !important",
        color: "#fff !important",
        padding: "8px 12px !important",
        whiteSpace: "nowrap",
        minHeight: "36px !important",
        height: "36px !important",
        fontWeight: 500
      }} type="button" label="Xem ngay" onClick={onClickDetail} data-sentry-element="CustomButton" data-sentry-source-file="index.tsx" />
      </Box>
    </Paper>;
};
export default JobCard;