import APICaller from "@src/api/product"
import useBase from "./base"
import { useCallback } from "react"


type ParamsProps = {
  [key: string]: string | number | boolean
}

interface Props {
  params?: ParamsProps
}

const useProduct = (props?: Props) => {
  const { params } = props || {}
  const { Service, ...rest } = useBase({
    api: APICaller,
    name: "products",
    params
  })

  const searchList = useCallback((params: ParamsProps) => {
    return Service.handlerAPI(APICaller.searchList(params), "searching")
  }, [])

  const getRelated = useCallback((slug: string, params: ParamsProps) => {
    return Service.handlerAPI(
      APICaller.getRelated(slug, params),
      "fetchingRelated"
    )
  }, [])

  return {
    ...rest,
    Service: {
      ...Service,
      searchList,
      getRelated
    }
  }
}

export default useProduct
