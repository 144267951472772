"use client";

import React from "react";
import classNames from "classnames";
import { makeStyles } from "tss-react/mui";
import { styleCommon } from "./styleCommon";
const useStyles = makeStyles()({
  ...styleCommon
});
const FormLabel = props => {
  const {
    label,
    required,
    msgError
  } = props;
  const {
    classes
  } = useStyles();
  return <label className={classNames(classes.label, {
    [classes.errorLabel]: Boolean(msgError)
  })} data-sentry-component="FormLabel" data-sentry-source-file="FormLabel.tsx">
      {label}
      {required && <span className="required">*</span>}
    </label>;
};
export default FormLabel;