"use client";

import Image from "next/image";
import Box from "@mui/material/Box";
import { useEffect, useState } from "react";
const NoImage = "/assets/images/no-image-placeholder.png";
const NextImage = props => {
  const {
    src,
    alt = "",
    sx,
    priority,
    sizes,
    loading,
    onLoad,
    ...rest
  } = props;
  const [imgSrc, setImgSrc] = useState(src);
  useEffect(() => {
    if (src) {
      setImgSrc(src);
    }
  }, [src]);
  return <Box sx={{
    position: "relative",
    width: "100%",
    height: "100%",
    "& img": {
      objectFit: "contain"
    },
    ...sx
  }} data-sentry-element="Box" data-sentry-component="NextImage" data-sentry-source-file="index.tsx">
      <Image fill priority={priority} loading={loading || "lazy"} src={imgSrc || NoImage} alt={alt || "image"}
    // sizes={
    //   sizes || "(max-width: 768px) 100vw, (max-width: 1200px) 100vw, 100vw"
    // }
    onError={e => {
      setImgSrc(NoImage);
    }} onLoad={onLoad} {...rest} data-sentry-element="Image" data-sentry-source-file="index.tsx" />
    </Box>;
};
export default NextImage;