export default {
  PROFILE: "profile",
  LOGIN_BEFORE: "login_before",
  LANGUAGE_TOKEN: "i18nextLng",

  CONFIG_REDUCER: "config_reducer",
  FCM_TOKEN: "fcm_token",

  DEVICE_SESSION: "device_session",

  SEARCH_HISTORY: "search_history",
  POPUP_BANNER_ID: "popup_banner_id",
}
