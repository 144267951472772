import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import { useEffect } from "react";
import { Icons } from "@src/components";
import { useResponsive } from "@src/hooks";
import useProduct from "@src/services/use-product";
import ProductDetail from "@src/sections/product-detail";
export default function ModalProduct(props) {
  const {
    selectedProduct,
    open,
    onClose
  } = props;
  const {
    isMobile
  } = useResponsive();
  const {
    Service,
    detail,
    fetchingId
  } = useProduct();
  useEffect(() => {
    if (selectedProduct?.slug) {
      Service.getById(selectedProduct.slug);
    }
  }, [selectedProduct?.slug]);
  return <Dialog open={open} onClose={onClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" sx={{
    zIndex: 999,
    "& .MuiDialog-paper": {
      margin: "0 16px",
      width: "100%",
      maxHeight: "100dvh"
    }
  }} maxWidth="lg" data-sentry-element="Dialog" data-sentry-component="ModalProduct" data-sentry-source-file="ModalProduct.tsx">
      <Box minWidth={{
      xs: "100%",
      lg: "1000px"
    }} sx={{
      position: "relative"
    }} data-sentry-element="Box" data-sentry-source-file="ModalProduct.tsx">
        <Icons sx={{
        position: "absolute",
        top: 4,
        right: 10,
        zIndex: 999
      }} type="button" name="close" onClick={onClose} data-sentry-element="Icons" data-sentry-source-file="ModalProduct.tsx" />
        <Box p={isMobile ? 2 : "16px 32px"} data-sentry-element="Box" data-sentry-source-file="ModalProduct.tsx">
          <ProductDetail isModal={true} fetchingId={fetchingId} detail={detail} data-sentry-element="ProductDetail" data-sentry-source-file="ModalProduct.tsx" />
        </Box>
      </Box>
    </Dialog>;
}