"use client"
import { useState, useEffect } from "react"
import { getDataFromResponse } from "@src/utils/api"
import fetchClient from "@src/api/lib/fetch-client"
type Props = {
  url: string,
  queries?: {
    [key: string]: any
  },
  config?: {
    [key: string]: any
  },
  onSuccess?: (res: any) => void,
  enabled?: boolean,
  method?: string
}

const useQuery = ({ url, queries, config, onSuccess, enabled }: Props) => {
  const [isReady, setIsReady] = useState(false)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [data, setData] = useState(null)
  const [othersData, setOthersData] = useState({})

  const getData = async (filters = {}) => {
    try {
      setLoading(true)
      const res = await fetchClient({
        method: "GET",
        url,
        queries: {
          ...queries,
          ...filters
        },
        config,
      })

      const { data: resData, ...rest } = getDataFromResponse(res)
      setData(resData)
      setOthersData(rest)

      setLoading(false)
      onSuccess && onSuccess(resData)
    } catch (error) {
      setError(error)
      setLoading(false)
    }
  }

  useEffect(() => {
    if (enabled === undefined || enabled === true) {
      setIsReady(true)
      getData()
    }
    return () => {
      setLoading(false)
      setError(null)
      setData(null)
      setOthersData({})
    }
  }, [enabled])

  const handleRefetch = (filters = {}, force = false) => {
    if(force) {
      getData(filters)
    } else {
      if (isReady) {
        getData(filters)
      }
    }
  }


  return {
    isReady,
    loading,
    error,
    data,
    othersData,
    setData,
    setLoading,
    refetch: handleRefetch
  }
}

export default useQuery
