"use client";

import Image from "next/image";
import { CloseWrapper } from "./styles";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import CloseIcon from "@src/assets/icons/close.svg";
import PlusIcon from "@src/assets/icons/plus.svg";
import LogoIcon from "@src/assets/icons/logo.svg";
import LogoSmallIcon from "@src/assets/icons/logo-small.svg";
import UserIcon from "@src/assets/icons/user.svg";
import SearchIcon from "@src/assets/icons/search.svg";
import HeartIcon from "@src/assets/icons/heart.svg";
import NotificationIcon from "@src/assets/icons/notification.svg";
import EcommerceIcon from "@src/assets/icons/e-commerce.svg";
import ArrowUp from "@src/assets/icons/arrow-up.svg";
import ArrowDown from "@src/assets/icons/arrow-down.svg";
import ArrowRight from "@src/assets/icons/arrow-right.svg";
import ArrowLeft from "@src/assets/icons/arrow-left.svg";
import Facebook from "@src/assets/icons/facebook-logo.svg";
import FacebookAddress from "@src/assets/icons/facebook-address.svg";
import Instagram from "@src/assets/icons/instagram-logo.svg";
import Tiktok from "@src/assets/icons/tiktok-logo.svg";
import FilterIcon from "@src/assets/icons/filter.svg";
import CaretLeft from "@src/assets/icons/caret-left.svg";
import CaretRight from "@src/assets/icons/caret-right.svg";
import StrokeIcon from "@src/assets/icons/stroke.svg";
import Clock from "@src/assets/icons/clock.svg";
import ArrowNext from "@src/assets/icons/arrow-next.svg";
import Link from "@src/assets/icons/link.svg";
import DocumentIcon from "@src/assets/icons/document.svg";
import RectangleIcon from "@src/assets/icons/rectangle.svg";
import HighLight_1 from "@src/assets/icons/hightlight_1.svg";
import HighLight_2 from "@src/assets/icons/hightlight_2.svg";
import HighLight_3 from "@src/assets/icons/hightlight_3.svg";
import HighLight_4 from "@src/assets/icons/hightlight_4.svg";
import ShopeeIcon from "@src/assets/icons/shopee.svg";
import NotFoundIcon from "@src/assets/icons/not-found.svg";
import Discount from "@src/assets/icons/discount.svg";
import FreeShip from "@src/assets/icons/freeship.svg";
import Voucher from "@src/assets/icons/voucher.svg";
import Payment from "@src/assets/icons/payment.svg";
import Delivery from "@src/assets/icons/delivery.svg";
import Change from "@src/assets/icons/change.svg";
import Headphone from "@src/assets/icons/headphone.svg";
import Sort from "@src/assets/icons/sort.svg";
import FacebookLogin from "@src/assets/icons/facebook-login.svg";
import GoogleLogin from "@src/assets/icons/google-login.svg";
import Account from "@src/assets/icons/account.svg";
import Phone from "@src/assets/icons/phone.svg";
import Email from "@src/assets/icons/email.svg";
import PassLock from "@src/assets/icons/pass-lock.svg";
import AboutValue from "@src/assets/icons/about-value.svg";
import AboutLeaf from "@src/assets/icons/about-leaf.svg";
import AboutVnFlag from "@src/assets/icons/about-vn-flag.svg";
import BranchMarker from "@src/assets/icons/branch-marker.svg";
import BranchPhone from "@src/assets/icons/branch-phone.svg";
import BranchClock from "@src/assets/icons/branch-clock.svg";
import BranchFindWay from "@src/assets/icons/branch-find-way.svg";
import BranchFindWayWhite from "@src/assets/icons/branch-find-way-white.svg";
import Location from "@src/assets/icons/location.svg";
import LocationBlack from "@src/assets/icons/location_black.svg";
import Package from "@src/assets/icons/package.svg";
import Setting from "@src/assets/icons/setting.svg";
import Power from "@src/assets/icons/power.svg";
import Voucher2 from "@src/assets/icons/voucher-2.svg";
import Calendar from "@src/assets/icons/calendar.svg";
import Bill from "@src/assets/icons/bill.svg";
import Sale from "@src/assets/icons/sale.svg";
import Shirt from "@src/assets/icons/shirt.svg";
import ShoppingBag from "@src/assets/icons/shopping-bag.svg";
import Radio from "@src/assets/icons/radio.svg";
import RadioChecked from "@src/assets/icons/radio-checked.svg";
import Edit from "@src/assets/icons/edit.svg";
import X from "@src/assets/icons/x.svg";
import EventClock from "@src/assets/icons/event-clock.svg";
import EventClockDark from "@src/assets/icons/event-clock-dark.svg";
import EventArrow from "@src/assets/icons/event-arrow.svg";
import EventNext from "@src/assets/icons/event-next.svg";
import EventPrev from "@src/assets/icons/event-prev.svg";
import EventHeart from "@src/assets/icons/event-heart.svg";
import EventDownArrow from "@src/assets/icons/event-down-arrow.svg";
import Eye from "@src/assets/icons/eye.svg";
import EyeSlash from "@src/assets/icons/eye-slash.svg";
import CheckCircleSuccess from "@src/assets/icons/check-circle.svg";
import COD from "@src/assets/icons/cod.svg";
import BankCard from "@src/assets/icons/bank-card.svg";
import Momo from "@src/assets/icons/momo.svg";
import Sepay from "@src/assets/icons/sepay.svg";
import VnPay from "@src/assets/icons/vn-pay.svg";
import Gift from "@src/assets/icons/gift.svg";
import HeartBag from "@src/assets/icons/heart-bag.svg";
import Hamburger from "@src/assets/icons/hamburger.svg";
import Home from "@src/assets/icons/home.svg";
import FlashSale from "@src/assets/icons/flash-sale.svg";
const SvgData = {
  close: {
    svg: CloseIcon,
    Wrapper: CloseWrapper
  },
  plus: {
    svg: PlusIcon
  },
  logo: {
    svg: LogoIcon
  },
  "logo-small": {
    svg: LogoSmallIcon
  },
  user: {
    svg: UserIcon
  },
  search: {
    svg: SearchIcon
  },
  heart: {
    svg: HeartIcon
  },
  notification: {
    svg: NotificationIcon
  },
  ecommerce: {
    svg: EcommerceIcon
  },
  "arrow-up": {
    svg: ArrowUp
  },
  "arrow-down": {
    svg: ArrowDown
  },
  "arrow-right": {
    svg: ArrowRight
  },
  "arrow-left": {
    svg: ArrowLeft
  },
  facebook: {
    svg: Facebook
  },
  "facebook-address": {
    svg: FacebookAddress
  },
  instagram: {
    svg: Instagram
  },
  tiktok: {
    svg: Tiktok
  },
  filter: {
    svg: FilterIcon
  },
  "caret-left": {
    svg: CaretLeft
  },
  "caret-right": {
    svg: CaretRight
  },
  stroke: {
    svg: StrokeIcon
  },
  clock: {
    svg: Clock
  },
  "arrow-next": {
    svg: ArrowNext
  },
  link: {
    svg: Link
  },
  document: {
    svg: DocumentIcon
  },
  rectangle: {
    svg: RectangleIcon
  },
  highlight_1: {
    svg: HighLight_1
  },
  highlight_2: {
    svg: HighLight_2
  },
  highlight_3: {
    svg: HighLight_3
  },
  highlight_4: {
    svg: HighLight_4
  },
  shopee: {
    svg: ShopeeIcon
  },
  "not-found": {
    svg: NotFoundIcon
  },
  discount: {
    svg: Discount
  },
  "free-ship": {
    svg: FreeShip
  },
  voucher: {
    svg: Voucher
  },
  "voucher-2": {
    svg: Voucher2
  },
  payment: {
    svg: Payment
  },
  delivery: {
    svg: Delivery
  },
  change: {
    svg: Change
  },
  headphone: {
    svg: Headphone
  },
  sort: {
    svg: Sort
  },
  "facebook-login": {
    svg: FacebookLogin
  },
  "google-login": {
    svg: GoogleLogin
  },
  account: {
    svg: Account
  },
  phone: {
    svg: Phone
  },
  email: {
    svg: Email
  },
  "pass-lock": {
    svg: PassLock
  },
  "about-value": {
    svg: AboutValue
  },
  "about-leaf": {
    svg: AboutLeaf
  },
  "about-vn-flag": {
    svg: AboutVnFlag
  },
  "branch-marker": {
    svg: BranchMarker
  },
  "branch-phone": {
    svg: BranchPhone
  },
  "branch-clock": {
    svg: BranchClock
  },
  "branch-find-way": {
    svg: BranchFindWay
  },
  "branch-find-way-white": {
    svg: BranchFindWayWhite
  },
  location: {
    svg: Location
  },
  "location_black": {
    svg: LocationBlack
  },
  package: {
    svg: Package
  },
  setting: {
    svg: Setting
  },
  power: {
    svg: Power
  },
  calendar: {
    svg: Calendar
  },
  bill: {
    svg: Bill
  },
  sale: {
    svg: Sale
  },
  shirt: {
    svg: Shirt
  },
  "shopping-bag": {
    svg: ShoppingBag
  },
  radio: {
    svg: Radio
  },
  "radio-checked": {
    svg: RadioChecked
  },
  edit: {
    svg: Edit
  },
  x: {
    svg: X
  },
  "event-clock": {
    svg: EventClock
  },
  "event-clock-dark": {
    svg: EventClockDark
  },
  "event-arrow": {
    svg: EventArrow
  },
  "event-next": {
    svg: EventNext
  },
  "event-prev": {
    svg: EventPrev
  },
  "event-heart": {
    svg: EventHeart
  },
  "event-down-arrow": {
    svg: EventDownArrow
  },
  eye: {
    svg: Eye
  },
  "eye-slash": {
    svg: EyeSlash
  },
  "check-circle-success": {
    svg: CheckCircleSuccess
  },
  cod: {
    svg: COD
  },
  "ebank": {
    svg: BankCard
  },
  momo: {
    svg: Momo
  },
  sepay: {
    svg: Sepay
  },
  vnpay: {
    svg: VnPay
  },
  gift: {
    svg: Gift
  },
  "heart-bag": {
    svg: HeartBag
  },
  hamburger: {
    svg: Hamburger
  },
  home: {
    svg: Home
  },
  "flash-sale": {
    svg: FlashSale
  }
};
const Icons = props => {
  const {
    name,
    type,
    onClick,
    sx,
    ...rest
  } = props;
  const {
    svg: SvgComponent,
    Wrapper = IconButton
  } = SvgData[name] || {};
  if (!SvgComponent) {
    return <></>;
  }
  if (type === "button") {
    return <Wrapper sx={{
      cursor: "pointer",
      borderRadius: "50%",
      width: "auto",
      height: "auto",
      ...sx
    }} color="inherit" aria-label={name} onClick={onClick} {...rest}>
        <SvgComponent />
      </Wrapper>;
  }
  return <Box onClick={onClick} sx={sx} display="flex" alignItems="center" {...rest} data-sentry-element="Box" data-sentry-component="Icons" data-sentry-source-file="index.tsx">
      <SvgComponent data-sentry-element="SvgComponent" data-sentry-source-file="index.tsx" />
    </Box>;
};
export default Icons;