"use client";

import { Controller } from "react-hook-form";
import classNames from "classnames";
import { makeStyles } from "tss-react/mui";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
const useStyles = makeStyles()({
  root: {
    width: "100%",
    flex: 1,
    display: "flex",
    flexDirection: "column",
    "& .MuiInputBase-root .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #C2C7D0"
    },
    "& span": {
      textTransform: "capitalize"
    },
    "& .MuiSvgIcon-root ": {
      width: 18,
      height: 18,
      color: "#A6AEBB"
    },
    "& .Mui-checked .MuiSvgIcon-root": {
      color: "#1866E1 !important"
    }
  },
  controller: {
    "& > div:nth-of-type(1)": {
      boxShadow: "unset"
    }
  },
  radioItem: {
    "& .MuiRadio-root": {
      padding: 6
    }
  },
  label: {
    fontSize: 12,
    lineHeight: "16px",
    color: "#000000",
    fontWeight: "400",
    marginBottom: 4,
    display: "inline-block",
    textTransform: "capitalize"
  },
  errorLabel: {
    color: "#f44336"
  },
  errorText: {
    color: "#f44336",
    marginLeft: 2,
    marginRight: 2,
    marginTop: 4,
    fontSize: "1rem"
  }
});
const FormGroupRadio = props => {
  const {
    classes
  } = useStyles();
  const {
    name,
    label,
    control,
    errors,
    options,
    disabled,
    showLabel = true,
    ...rest
  } = props;
  return <div className={classes.root} data-sentry-component="FormGroupRadio" data-sentry-source-file="FormGroupRadio.tsx">
      {showLabel && <label className={classNames(classes.label, {
      [classes.errorLabel]: errors?.message
    })}>
          {label}
        </label>}
      <Controller {...rest} className={classes.controller} control={control} name={name} render={({
      field
    }) => {
      return <RadioGroup aria-labelledby="demo-controlled-radio-buttons-group" className={classes.radioItem} defaultValue={options[0]?.value} {...field}>
              {options?.length > 0 && options.map((item, index) => <FormControlLabel key={index} value={item.value} control={<Radio />} label={item.label} />)}
            </RadioGroup>;
    }} data-sentry-element="Controller" data-sentry-source-file="FormGroupRadio.tsx" />
      {errors?.message && <span className={classes.errorText}>{errors.message}</span>}
    </div>;
};
export default FormGroupRadio;