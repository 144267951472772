"use client";

import InputBase from "@mui/material/InputBase";
import Box from "@mui/material/Box";
import { Icons } from "@src/components";
import { styled } from "@mui/system";
import { alpha } from "@mui/material/styles";
import useDebounce from "@src/hooks/use-debounce";
import React, { useState, useEffect } from "react";
const InputBaseStyles = styled(InputBase)({
  color: "inherit",
  width: "100%",
  height: 48,
  padding: "8px",
  "& input": {
    height: "fit-content",
    padding: 0,
    boxSizing: "border-box",
    "&::placeholder": {
      lineHeight: "22px"
    }
  }
});
const BoxWrapper = styled(Box)(({
  theme
}) => ({
  position: "relative",
  display: "flex",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: "#F3F3F3",
  width: "auto",
  border: "none",
  padding: theme.spacing(0, 1),
  marginLeft: theme.spacing(2),
  marginRight: theme.spacing(2),
  "& svg": {
    width: 24,
    height: 24
  }
}));
const SearchInput = React.forwardRef((props, ref) => {
  const {
    debounceTime = 300,
    defaultValue,
    onChange,
    onSearch,
    placeholder,
    sx = {},
    iconPosition = "start",
    onFocus,
    onBlur
  } = props;
  const [searchText, setSearchText] = useState("");
  const searchTextDebounce = useDebounce(searchText, debounceTime);
  useEffect(() => {
    if (searchText !== defaultValue) setSearchText(defaultValue);
  }, [defaultValue]);
  useEffect(() => {
    const trimSearchValue = (searchTextDebounce || " ").trim();
    onChange?.(trimSearchValue);
    const handleSearch = function (e) {
      if (e.key === "Enter" && onSearch) {
        onSearch(trimSearchValue);
      }
    };
    document.addEventListener("keydown", handleSearch);
    return () => {
      document.removeEventListener("keydown", handleSearch);
    };
  }, [searchTextDebounce]);
  return <BoxWrapper ref={ref} sx={sx}>
      {iconPosition === "start" && <Icons name="search" />}
      <InputBaseStyles value={searchText} placeholder={placeholder || "Search"} inputProps={{
      "aria-label": "search"
    }} onChange={e => setSearchText(e.target.value)} onFocus={onFocus} onBlur={onBlur} />
      {iconPosition === "end" && <Icons name="search" />}
    </BoxWrapper>;
});
export default SearchInput;