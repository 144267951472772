import React from "react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Image from "next/image";
import { NextLink, Icons, PriceTypography, NextImage, ConfirmButton, CancelButton } from "@src/components";
import { formatPrice } from "@src/utils/common";
import { useState, useEffect, useCallback } from "react";
import useInventoryProduct from "./use-product";
import { ROUTES } from "@src/constants/routes";
import useRouterClient from "@src/hooks/use-router-client";
import ProductModel from "@src/models/product";
import { scrollPageTo } from "@src/utils/scrollElement";
import { useSearchParams } from "next/navigation";
import { styled } from "@mui/system";
const SizeBox = styled(Box)(({
  theme,
  active,
  disabled
}: {
  theme: any;
  active: boolean;
  disabled: boolean;
}) => ({
  height: 24,
  overflow: "hidden",
  padding: "6px 10px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  cursor: "pointer",
  background: "white",
  borderRadius: "16px",
  ...(active && {
    border: "1.5px solid black",
    "& p": {
      fontWeight: 700
    }
  }),
  ...(disabled && {
    opacity: 0.5,
    cursor: "not-allowed",
    pointerEvents: "none"
  })
}));
interface Props {
  isMobile: boolean;
  isModal: boolean;
  detail: ProductModel;
  onSelectedImage: (value: string) => void;
  onOpenSizeModal: (e: any) => void;
}
const InventoryOption = (props: Props) => {
  const {
    isMobile,
    isModal,
    detail,
    onSelectedImage,
    onOpenSizeModal
  } = props;
  const {
    router
  } = useRouterClient();
  const searchParams = useSearchParams();
  const stockAnchor = searchParams.get("stockAnchor") || "";
  const {
    loadingWithKey = {},
    currentInventory,
    firstAttributeOptions,
    secondAttributeOptions,
    handleAddToCart,
    handleChangeFirstAttribute,
    handleChangeSecondAttribute,
    onUpdateQuantity,
    count,
    promotionPrice,
    originalPrice
  } = useInventoryProduct(detail, true, !isModal);
  const handleScrollToBrachStock = () => {
    if (!document) return;
    const stockElement = document.getElementById("branch_stock");
    if (stockElement) {
      const anchorY = stockElement.getBoundingClientRect().top + window.scrollY - 20;
      scrollPageTo(anchorY, 200, () => {
        // clear anchorStock after scroll and keep other params
        const params = new URLSearchParams(window.location.search);
        params.delete("stockAnchor");
        window.history.replaceState({}, "", `${window.location.pathname}?${params.toString()}`);
      });
    } else {
      const params = new URLSearchParams(window.location.search);
      params.delete("stockAnchor");
      window.history.replaceState({}, "", `${window.location.pathname}?${params.toString()}`);
    }
  };
  React.useEffect(() => {
    if (stockAnchor === "true") {
      setTimeout(() => {
        handleScrollToBrachStock();
      }, 200);
    }
  }, [stockAnchor]);
  const handleChangeColor = useCallback((value: string) => {
    handleChangeFirstAttribute(value);
    const firstAttribute = firstAttributeOptions?.options.find(option => option.value === value);
    if (firstAttribute?.image) {
      onSelectedImage(firstAttribute.image);
    }
  }, [handleChangeFirstAttribute]);
  const handleBuyNow = () => {
    handleAddToCart({
      showToast: true,
      onSuccess: () => {
        router.push(ROUTES.CART);
      },
      loadingKey: "buyNow"
    });
  };
  const isDisabledAddToCart = count < 1 || currentInventory?.stock_quantity < 1 || !currentInventory?.id;
  return <Stack flexDirection="column" gap={{
    xs: 1.5,
    md: 2
  }} width="100%" data-sentry-element="Stack" data-sentry-component="InventoryOption" data-sentry-source-file="info-and-inventory.tsx">
      <Typography fontSize={isMobile ? 14 : 20} fontWeight={700} lineHeight="30px" data-sentry-element="Typography" data-sentry-source-file="info-and-inventory.tsx">
        {detail?.name || ""}
      </Typography>
      {/* Hidden modal in mobile */}
      {!isMobile && <Typography variant={isMobile ? "body1" : "body"} fontSize={16} fontWeight={400} lineHeight="24px">
          {detail?.short_description || ""}
        </Typography>}
      <Stack gap={2} data-sentry-element="Stack" data-sentry-source-file="info-and-inventory.tsx">
        <Typography fontSize={isMobile ? 16 : 20} fontWeight={700} lineHeight="30px" data-sentry-element="Typography" data-sentry-source-file="info-and-inventory.tsx">
          {formatPrice(promotionPrice || originalPrice)}
        </Typography>
        <PriceTypography fontSize={isMobile ? 16 : 20} fontWeight={700} lineHeight="30px" isNull={!promotionPrice} data-sentry-element="PriceTypography" data-sentry-source-file="info-and-inventory.tsx">
          {formatPrice(originalPrice)}
        </PriceTypography>
      </Stack>
      {firstAttributeOptions?.options.length > 0 && <Stack flexDirection="column" gap={2}>
          <Stack gap={2}>
            <Typography variant={isMobile ? "body1" : "body"} fontWeight={600} lineHeight="24px">
              {firstAttributeOptions.name}:
            </Typography>
            <Typography variant={isMobile ? "body1" : "body"} fontWeight={400} lineHeight="24px">
              {currentInventory?.firstAttribute.value}
            </Typography>
          </Stack>
          <Stack gap={2} flexWrap="wrap">
            {firstAttributeOptions?.options.map((item, index) => <Box key={index} width={isModal ? 40 : 58} height={isModal ? 40 : 58} borderRadius="8px" overflow="hidden" sx={{
          cursor: "pointer",
          ...(currentInventory?.firstAttribute?.value === item.value && {
            border: "1px solid black"
          })
        }} role="button" onClick={() => handleChangeColor(item.value)}>
                <Image width={isModal ? 40 : 58} height={isModal ? 40 : 58} src={item.small_image} style={{
            objectFit: "cover"
          }} />
              </Box>)}
          </Stack>
        </Stack>}
      {secondAttributeOptions?.options?.length > 0 && <Stack flexDirection="column" gap={2}>
          <Typography variant={isMobile ? "body1" : "body"} fontWeight={600} lineHeight="24px">
            Size
          </Typography>
          <Stack gap={2}>
            {secondAttributeOptions?.options?.map((item, index) => <SizeBox key={index} active={currentInventory?.secondAttribute?.value === item.value} onClick={() => handleChangeSecondAttribute(item.value)}
        // disabled={item?.stock_quantity == 0}
        >
                <Typography variant="body1" fontWeight={400} lineHeight="24px" textTransform="uppercase">
                  {item?.value}
                </Typography>
              </SizeBox>)}
          </Stack>
          <NextLink style={{
        color: "black",
        fontSize: "16px",
        textDecoration: "underline"
      }} href="#" passHref onClick={onOpenSizeModal}>
            <Stack gap={0.5} alignItems="center">
              <Typography variant={isMobile ? "body1" : "body"}>
                Hướng dẫn chọn size
              </Typography>

              <Icons sx={{
            marginTop: "-4px"
          }} name="caret-right" />
            </Stack>
          </NextLink>
        </Stack>}
      <Typography fontSize="12px" color="#707070" data-sentry-element="Typography" data-sentry-source-file="info-and-inventory.tsx">
        Tồn kho: {currentInventory?.stock_quantity || 0}
      </Typography>
      <Stack gap={2} data-sentry-element="Stack" data-sentry-source-file="info-and-inventory.tsx">
        <Typography variant={isMobile ? "body1" : "body"} data-sentry-element="Typography" data-sentry-source-file="info-and-inventory.tsx">Số lượng</Typography>
        <Stack gap={1} data-sentry-element="Stack" data-sentry-source-file="info-and-inventory.tsx">
          <Button sx={{
          border: "none",
          padding: "0px",
          color: "black",
          minWidth: "32px",
          minHeight: "24px",
          backgroundColor: "transparent",
          "&.Mui-disabled": {
            opacity: 0.5,
            pointerEvents: "none"
          }
        }} disabled={count <= 1 || currentInventory?.stock_quantity <= 1} onClick={() => {
          onUpdateQuantity(count - 1);
        }} data-sentry-element="Button" data-sentry-source-file="info-and-inventory.tsx">
            <svg width="14" height="2" viewBox="0 0 16 2" fill="none" xmlns="http://www.w3.org/2000/svg" data-sentry-element="svg" data-sentry-source-file="info-and-inventory.tsx">
              <path d="M1 1.25H15" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" data-sentry-element="path" data-sentry-source-file="info-and-inventory.tsx" />
            </svg>
          </Button>
          <SizeBox sx={{
          border: "1.5px solid black",
          borderRadius: "16px",
          minWidth: 48
        }} data-sentry-element="SizeBox" data-sentry-source-file="info-and-inventory.tsx">
            <span>{count}</span>
          </SizeBox>
          <Button sx={{
          border: "none",
          padding: "0px",
          color: "black",
          minWidth: "32px",
          minHeight: "24px",
          backgroundColor: "transparent",
          "&.Mui-disabled": {
            opacity: 0.5,
            pointerEvents: "none"
          }
        }} disabled={count < 1 || currentInventory?.stock_quantity <= 1 || currentInventory?.stock_quantity === count} onClick={() => {
          onUpdateQuantity(count + 1);
        }} data-sentry-element="Button" data-sentry-source-file="info-and-inventory.tsx">
            <svg width="14" height="14" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" data-sentry-element="svg" data-sentry-source-file="info-and-inventory.tsx">
              <path d="M8 1.25V15.25M1 8.25H15" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" data-sentry-element="path" data-sentry-source-file="info-and-inventory.tsx" />
            </svg>
          </Button>
        </Stack>
        {currentInventory?.stock_quantity < 1 && <Typography variant="body1" fontWeight={400} lineHeight="24px" color="red" marginLeft="-4px">
            Hết hàng
          </Typography>}
      </Stack>
      {/* Hidden Modal of Mobile */}
      {!isModal && <Stack sx={{
      position: "relative",
      backgroundColor: "transparent",
      padding: 0,
      left: 0,
      right: 0,
      bottom: 0,
      zIndex: 99,
      width: "100%",
      margin: "0px auto"
    }} gap={2}>
          <CancelButton minHeightMd={40} minWidth={160} label="Thêm vào giỏ" sx={{
        span: {
          textTransform: "none"
        }
      }} disabled={isDisabledAddToCart} loading={loadingWithKey?.addCart} onClick={handleAddToCart} />
          <ConfirmButton minHeightMd={40} minWidth={220} minWidthMd="50%" label="Mua ngay" sx={{
        span: {
          textTransform: "none"
        }
      }} disabled={isDisabledAddToCart} loading={loadingWithKey?.buyNow} onClick={handleBuyNow} />
        </Stack>}

      {!isModal && <Stack alignItems="center" gap="4px" onClick={handleScrollToBrachStock} role="button" sx={{
      marginLeft: "-4px",
      cursor: "pointer"
    }}>
          <Icons name="location_black" />
          <Typography variant="body1" fontSize={14} fontWeight={500} sx={{
        textDecoration: "underline",
        color: "#000000",
        "&:hover": {
          color: "#000000",
          opacity: 0.6
        }
      }}>
            Địa chỉ còn hàng
          </Typography>
        </Stack>}

      {isModal && <>
          <Stack sx={{
        padding: "8px 0px",
        zIndex: 99,
        width: "100%",
        margin: "auto"
      }} justifyContent="center" gap={2} flexDirection={{
        xs: "column-reverse",
        md: "row"
      }}>
            <CancelButton minHeightMd={40} minWidth={160} label="Thêm vào giỏ" sx={{
          span: {
            textTransform: "none"
          }
        }} disabled={isDisabledAddToCart} loading={loadingWithKey?.addCart} onClick={handleAddToCart} />
            <ConfirmButton minHeightMd={40} minWidth={240} label="Mua ngay" sx={{
          span: {
            textTransform: "none"
          }
        }} disabled={isDisabledAddToCart} loading={loadingWithKey?.buyNow} onClick={handleBuyNow} />
          </Stack>
          <NextLink href={`${ROUTES.PRODUCT}/${detail?.slug}?stockAnchor=true`} passHref sx={{
        marginLeft: "-4px",
        textDecoration: "underline",
        "&:hover p": {
          color: "#000000",
          opacity: 0.6
        }
      }}>
            <Stack alignItems="center" gap="4px">
              <Icons name="location_black" />
              <Typography variant="body1" fontSize={14} fontWeight={500}>
                Địa chỉ còn hàng
              </Typography>
            </Stack>
          </NextLink>
          <NextLink style={{
        color: "#87BD5E",
        fontSize: "14px",
        fontWeight: 600,
        textDecoration: "underline"
      }} href={`${ROUTES.PRODUCT}/${detail?.slug}`} passHref>
            Xem chi tiết đầy đủ
          </NextLink>
        </>}
    </Stack>;
};
export default InventoryOption;