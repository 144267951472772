"use client";

import { useState, useMemo } from "react";
import useOnClickOutside from "@src/hooks/use-click-outside";
import { DayPicker } from "react-day-picker";
import dayjs from "dayjs";
import { defaultDateFormat } from "../constants";
import Button from "@mui/material/Button";
import { useStyles } from "./styles";
import "react-day-picker/dist/style.css";
const arrWeekDatePicker = [{
  title: "appointment.in_1_week",
  time: dayjs(new Date()).add(7, "days")
}, {
  title: "appointment.in_2_week",
  time: dayjs(new Date()).add(14, "days")
}, {
  title: "appointment.in_3_week",
  time: dayjs(new Date()).add(21, "days")
}, {
  title: "appointment.in_4_week",
  time: dayjs(new Date()).add(28, "days")
}, {
  title: "appointment.in_5_week",
  time: dayjs(new Date()).add(35, "days")
}, {
  title: "appointment.in_6_week",
  time: dayjs(new Date()).add(42, "days")
}];

// input, output: dateStr
const SingleDatePicker = props => {
  const {
    id = "",
    container,
    show,
    hasWeek,
    value = "",
    onClose,
    onChange,
    dateFormat = defaultDateFormat,
    isTodayFooter,
    ...rest
  } = props;
  const [popperElement, setPopperElement] = useState(null);
  const {
    classes
  } = useStyles();
  useOnClickOutside(popperElement, () => {
    if (show) {
      onClose(false);
    }
  });
  const handleDaySelect = date => {
    if (date) {
      onChange(dayjs(date).format(dateFormat));
      onClose(false);
    } else {
      onChange(value);
    }
  };
  if (!show || !container) return null;
  return <div id={id} tabIndex={-1} ref={setPopperElement} role="dialog" className={classes.singleDatePickerRoot} data-sentry-component="SingleDatePicker" data-sentry-source-file="index.tsx">
      <DayPicker className="bingo-single-datepicker" mode="single" weekStartsOn={1} selected={new Date(value)} onSelect={handleDaySelect} footer={isTodayFooter ? renderDatePickerFooter() : null} {...rest} data-sentry-element="DayPicker" data-sentry-source-file="index.tsx" />
      {hasWeek && <div className="group-button">
          {arrWeekDatePicker.map((w, index) => <Button key={index} variant="outline" color="primary" onClick={() => handleDaySelect(w.time)}>
              {w.title}
            </Button>)}
        </div>}
    </div>;
};
export default SingleDatePicker;