"use client";

import MuiDialog from "@mui/material/Dialog";
import MuiDialogContent from "@mui/material/DialogContent";
import MuiDialogActions from "@mui/material/DialogActions";
import DialogTitle from "./ModalHeader";
import { Transition } from "@src/components";
import { styled } from "@mui/system";
export const DialogContent = styled(MuiDialogContent)({
  padding: "12px 16px"
});
export const DialogActions = styled(MuiDialogActions)({
  margin: 0,
  padding: "16px"
});
export default function Modal({
  title,
  onClose,
  open,
  children,
  sxWrapper = {},
  ...rest
}) {
  return <MuiDialog fullWidth maxWidth="sm" open={open} onClose={onClose} TransitionComponent={Transition} sx={{
    "& .MuiDialog-paper": {
      overflow: "auto"
    },
    ...sxWrapper
  }} {...rest} data-sentry-element="MuiDialog" data-sentry-component="Modal" data-sentry-source-file="index.tsx">
      <DialogTitle onClose={onClose} data-sentry-element="DialogTitle" data-sentry-source-file="index.tsx">{title}</DialogTitle>
      {children}
    </MuiDialog>;
}