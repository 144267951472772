"use client"
import { useRouter } from "next/navigation"

const useRouterClient = () => {
  const router = useRouter()
  return {
    router
  }
}

export default useRouterClient
