import React from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
interface Props {
  sxTitle?: object;
  fontSize?: number;
  lineHeight?: string;
  title: string;
  description: string;
  fontWeightDescription?: number;
}
const PageTitle = ({
  sxTitle = {},
  fontSize,
  lineHeight,
  title,
  description,
  fontWeightDescription
}: Props) => {
  return <Box sx={{
    margin: "auto",
    textAlign: "center",
    maxWidth: "800px"
  }} data-sentry-element="Box" data-sentry-component="PageTitle" data-sentry-source-file="index.tsx">
      <Typography component="h2" sx={{
      fontSize: {
        xs: 20,
        md: fontSize || 48
      },
      fontWeight: 700,
      lineHeight: {
        xs: "24px",
        md: lineHeight || "72px"
      },
      letterSpacing: "-1.2px",
      ...sxTitle
    }} data-sentry-element="Typography" data-sentry-source-file="index.tsx">
        {title}
      </Typography>
      <Typography mt={2} variant="body1" component="p" sx={{
      lineHeight: "20px",
      fontWeight: fontWeightDescription
    }} data-sentry-element="Typography" data-sentry-source-file="index.tsx">
        {description}
      </Typography>
    </Box>;
};
export default PageTitle;