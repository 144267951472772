import { makeStyles } from "tss-react/mui"
import TableCell from "@mui/material/TableCell"
import TableRow from "@mui/material/TableRow"
import { styled } from "@mui/system"

export const MuiTableCell = styled(TableCell)({
  padding: "8px 12px",
  fontSize: 14,
  minHeight: 48,
  height: 48,
  textAlign: "center"
})

export const MuiTableRow = styled(TableRow)({
  height: 64,
  // hide last border
  "&:last-child, &:last-child td, &:last-child th": {
    border: 0,
    boxShadow: "none"
  }
})

// const useStyles = makeStyles()(theme => ({
//   table: {
//     "& .table": {
//       marginBottom: 0,
//       background: "white",
//       "& tr": {
//         borderBottom: "1px solid #dee2e6",
//         "& th, td": {
//           verticalAlign: "center"
//         }
//       }
//     },
//     "& .pagination": {
//       justifyContent: "flex-end"
//     }
//   },
//   noPagination: {
//     "& .react-bootstrap-table-pagination": {
//       display: "none"
//     }
//   },
//   paginationTotal: {
//     textAlign: "center",
//     color: "#828282"
//   },
//   btn: {
//     marginTop: 8,
//     textAlign: "center"
//   },
//   btnMore: {
//     backgroundColor: "#005dff",
//     color: "#fff",
//     textTransform: "unset",
//     borderRadius: "20px",
//     "&:hover": {
//       backgroundColor: "#005dff"
//     },
//     width: 170,
//     height: 40,
//     padding: "10.9px 25.5px 10.1px"
//   },
//   noData: {
//     textAlign: "center",
//     fontStyle: "italic",
//     padding: "10px 0"
//   },
//   pagination: {
//     backgroundColor: "#fff",
//     borderLeft: "0.5px solid #dee2e6",
//     borderRight: "0.5px solid #dee2e6",
//     borderBottom: "0.5px solid #dee2e6",
//     padding: "25px 0"
//   },
//   tableScroll: {
//     overflow: "scroll"
//   }
// }))
// export default useStyles
